<template>
  <card :title="$t('offers.details.offer_promotions_section.title')" icon="" id="offer-promotions-information">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('offers.details.offer_promotions_section.count')"
        :value="offerPromotionsItemCount || '0'"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text class="text-sm-center" :label="$t('offers.details.offer_promotions_section.action')">
        <link-secondary-action slot="labelTextLink" @click="showAddOfferPromotionModal()">
          {{ $t('offers.details.offer_promotions_section.add') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddOfferPromotion" close>
        <add-offer-promotion-action
          :offer-id="offer.id"
          @success="offerPromotionCreated"
          @close="closeAddOfferPromotionModal"
        ></add-offer-promotion-action>
      </modal-action>
      <modal-confirm
        :show-modal="showActivateOfferPromotion"
        :title="$t(`offer_promotions.activate_action.confirm.title`)"
        context="activateOfferPromotion"
        :text="
          $t(`offer_promotions.activate_action.confirm.text`, {
            offersPromotionName: `${offerPromotionIdToActivate}`,
          })
        "
        :cancel_text="`offer_promotions.activate_action.confirm.button_cancel`"
        :submit_text="`offer_promotions.activate_action.confirm.button_submit`"
        :submit_working="working"
        @submit="activateOfferPromotion"
        @close="closeActivateOfferPromotionModal"
      ></modal-confirm>
      <modal-confirm
        :show-modal="showDeactivateOfferPromotion"
        :title="$t(`offer_promotions.deactivate_action.confirm.title`)"
        context="deactivateOfferPromotion"
        :text="$t(`offer_promotions.deactivate_action.confirm.text`, { offersPromotionName: `${offerPromotionIdToDeactivate}` })"
        :cancel_text="`offer_promotions.deactivate_action.confirm.button_cancel`"
        :submit_text="`offer_promotions.deactivate_action.confirm.button_submit`"
        :submit_working="working"
        @submit="deactivateOfferPromotion"
        @close="closeDeactivateOfferPromotionModal"
      ></modal-confirm>
      <label-none v-if="offerPromotions.length === 0">{{ $t('offers.offer_promotions_table.no_found') }}</label-none>

      <div v-else>
        <table class="responsive-table">
          <thead>
            <tr>
              <td>{{ $t('offers.offer_promotions_table.fields.id') }}</td>
              <td>{{ $t('offers.offer_promotions_table.fields.type') }}</td>
              <td>{{ $t('offers.offer_promotions_table.fields.reward_percent') }}</td>
              <td>{{ $t('offers.offer_promotions_table.fields.from_date') }}</td>
              <td>{{ $t('offers.offer_promotions_table.fields.to_date') }}</td>
              <td>{{ $t('offers.offer_promotions_table.fields.status') }}</td>
              <td>{{ $t('offers.offer_promotions_table.fields.active') }}</td>
              <td>{{ $t('offers.offer_promotions_table.fields.action') }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in offerPromotions" :key="index">
              <td>
                <h5>{{ $t('offers.offer_promotions_table.fields.id') }}:</h5>
                <span>
                  <router-link :to="{ name: 'offer_promotion_detail', params: { offerPromotionId: item.id } }">
                    {{ item.id }}
                  </router-link>
                </span>
              </td>
              <td>
                <h5>{{ $t('offers.offer_promotions_table.fields.type') }}:</h5>
                <span>{{ $t('offer_promotions.type.' + item.type) }}</span>
              </td>
              <td align="center">
                <h5>{{ $t('offers.offer_promotions_table.fields.reward_percent') }}:</h5>
                <span>{{ item.bonus.reward_percent | floatToPercentage }}</span>
              </td>
              <td>
                <h5>{{ $t('offers.offer_promotions_table.fields.from_date') }}:</h5>
                <span>
                  {{
                    (item.start_date + ' ' + item.start_time)
                      | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offer.timezone, returnNull: true })
                  }}
                </span>
              </td>
              <td>
                <h5>{{ $t('offers.offer_promotions_table.fields.to_date') }}:</h5>
                <span>
                  {{
                    (item.end_date + ' ' + item.end_time)
                      | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offer.timezone, returnNull: true })
                  }}
                </span>
              </td>
              <td>
                <h5>{{ $t('offers.offer_promotions_table.fields.status') }}:</h5>
                <span><offer-promotion-status :status="item.status"></offer-promotion-status></span>
              </td>
              <td>
                <h5>{{ $t('offers.offer_promotions_table.fields.active') }}:</h5>
                <span><offer-promotion-active :active="`${item.active}`"></offer-promotion-active></span>
              </td>
              <td>
                <h5>{{ $t('offers.offer_promotions_table.fields.action') }}:</h5>
                <span>
                  <link-secondary-action v-if="!item.active" slot="labelTextLink" @click="openActivateOfferPromotionModal(item.id)">
                    {{ $t('offers.offer_promotions_table.activate_link') }}
                  </link-secondary-action>
                  <link-secondary-action v-else-if="item.active" slot="labelTextLink" @click="openDeactivateOfferPromotionModal(item.id)">
                    {{ $t('offers.offer_promotions_table.deactivate_link') }}
                  </link-secondary-action>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-xs-center">
          <v-pagination v-if="offerPromotionsPageCount > 1" v-model="pagination" :length="offerPromotionsPageCount"></v-pagination>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import AddOfferPromotionAction from '../offer-promotions/actions/AddOfferPromotionAction.vue';
import OfferPromotionStatus from '../offer-promotions/components/OfferPromotionStatus.vue';
import OfferPromotionActive from '../offer-promotions/components/OfferPromotionActive.vue';

export default {
  name: 'offer-promotions',
  components: {
    AddOfferPromotionAction,
    OfferPromotionStatus,
    OfferPromotionActive,
  },
  mixins: [security, disableWithError, actionErrorTrackable],
  data() {
    return {
      pagination: 1,
      showAddOfferPromotion: false,
      showActivateOfferPromotion: false,
      showDeactivateOfferPromotion: false,

      offerPromotionIdToActivate: null,

      offerPromotionIdToDeactivate: null,
    };
  },
  mounted() {
    this.pagination = this.offerPromotionsPageNumber;
  },
  watch: {
    async offer() {
      await this.fetchOfferPromotions();
    },
    async pagination(newValue) {
      await this.fetchOfferPromotions();
    },
  },
  computed: {
    ...mapGetters('offers', ['offer', 'offerPromotionsPageCount', 'offerPromotionsItemCount', 'offerPromotionsPageNumber', 'offerPromotions']),
  },
  methods: {
    async fetchOfferPromotions() {
      await this.executeAction({ action: 'offers/listOfferPromotion' }, { id: this.offer.id, page: this.pagination });
    },
    showAddOfferPromotionModal() {
      this.showAddOfferPromotion = true;
    },
    async offerPromotionCreated() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offer_promotions.create_action.success` });
      this.showAddOfferPromotion = false;
      await this.fetchOfferPromotions();
    },
    closeAddOfferPromotionModal() {
      this.showAddOfferPromotion = false;
    },

    async activateOfferPromotion() {
      await this.executeAction(
        {
          action: 'offers/activateOfferPromotion',
          name: 'activateOfferPromotion',
          success: this.activateOfferPromotionSuccess,
        },
        { id: this.offerPromotionIdToActivate },
      );
    },
    async activateOfferPromotionSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offer_promotions.activate_action.success` });
      this.closeActivateOfferPromotionModal();
    },
    openActivateOfferPromotionModal(id) {
      this.offerPromotionIdToActivate = id;
      this.showActivateOfferPromotion = true;
    },
    closeActivateOfferPromotionModal() {
      this.offerPromotionIdToActivate = null;
      this.showActivateOfferPromotion = false;
    },
    async deactivateOfferPromotion() {
      await this.executeAction(
        {
          action: 'offers/deactivateOfferPromotion',
          name: 'deactivateOfferPromotion',
          success: this.deactivateOfferPromotionSuccess,
        },
        { id: this.offerPromotionIdToDeactivate },
      );
    },
    async deactivateOfferPromotionSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offer_promotions.deactivate_action.success` });
      this.closeDeactivateOfferPromotionModal();
    },
    openDeactivateOfferPromotionModal(id) {
      this.offerPromotionIdToDeactivate = id;
      this.showDeactivateOfferPromotion = true;
    },
    closeDeactivateOfferPromotionModal() {
      this.offerPromotionIdToDeactivate = null;
      this.showDeactivateOfferPromotion = false;
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';
#offers-url-information h4 {
  padding-top: 0;
}
</style>
