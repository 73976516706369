<template>
  <card :title="$t('inventory.offer_promotions.title')" icon="">
    <template slot="content">
      <inventory-offer-promotions-table
        v-if="inventoryOfferPromotions.length"
        :items="inventoryOfferPromotions"
        :inventory-id="inventory.id"
      ></inventory-offer-promotions-table>
      <label-none v-if="inventoryOfferPromotions.length === 0">{{ $t('system.na') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import InventoryOfferPromotionsTable from './InventoryOfferPromotionsTable.vue';

export default {
  name: 'inventory-offer-promotions',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { InventoryOfferPromotionsTable },
  data() {
    return {
      pagination: 1,
    };
  },
  watch: {
    async inventory() {
      return this.executeAction(
        { action: 'inventory/listInventoryOfferPromotions' },
        { id: this.inventory.id, page: this.inventoryOfferPromotionsPageNumber },
      );
    },
    inventoryOfferPromotionsPageNumber() {
      this.pagination = this.inventoryOfferPromotionsPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'inventory/changeInventoryOfferPromotionsPage',
          success: this.success,
        },
        {
          id: this.inventory.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('inventory', ['inventory', 'inventoryOfferPromotions', 'inventoryOfferPromotionsPageCount', 'inventoryOfferPromotionsPageNumber']),
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
