<template>
  <table class="responsive-table" id="user-corporate-orders-table">
    <thead>
      <tr>
        <td>{{ $t('user.corporate_orders_table.fields.date_purchased') }}</td>
        <td>{{ $t('user.corporate_orders_table.fields.order_id') }}</td>
        <td>{{ $t('user.corporate_orders_table.fields.partner_id') }}</td>
        <td>{{ $t('user.corporate_orders_table.fields.offer_id') }}</td>
        <td>{{ $t('user.corporate_orders_table.fields.offer_promotion_id') }}</td>
        <td align="right">{{ $t('user.corporate_orders_table.fields.amount') }}</td>
        <td align="right">{{ $t('user.corporate_orders_table.fields.quantity') }}</td>
        <td>{{ $t('user.corporate_orders_table.fields.order_status') }}</td>
        <td>{{ $t('user.corporate_orders_table.fields.activation_code_batch_id') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('user.corporate_orders_table.fields.date_purchased') }}:</h5>
          <span>{{ item.creation_date | formatDateHourMin }}</span>
        </td>
        <td>
          <h5>{{ $t('user.corporate_orders_table.fields.order_id') }}:</h5>
          <span>
            {{ item.id }}
          </span>
        </td>
        <td>
          <h5>{{ $t('user.corporate_orders_table.fields.partner_id') }}:</h5>
          <span>{{ item.partner_id }}</span>
        </td>
        <td>
          <h5>{{ $t('user.corporate_orders_table.fields.offer_id') }}:</h5>
          <link-secondary-route :can-access="canViewOffer()" :route-name="'offers_details'" :route-params="{ offerId: item.offer_id }">
            {{ item.offer_id }}
          </link-secondary-route>
        </td>
        <td>
          <h5>{{ $t('user.corporate_orders_table.fields.offer_promotion_id') }}:</h5>
          <link-secondary-route
            v-if="item.offer_promotion_id"
            :can-access="canViewOffer()"
            :route-name="'offer_promotion_detail'"
            :route-params="{ offerId: item.offer_id, offerPromotionId: item.offer_promotion_id }"
          >
            {{ item.offer_promotion_id }}
          </link-secondary-route>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td align="right">
          <h5>{{ $t('user.corporate_orders_table.fields.amount') }}:</h5>
          <span>{{ item.amount | currency | dollarSignI18n }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('user.corporate_orders_table.fields.quantity') }}:</h5>
          <span>{{ item.quantity }}</span>
        </td>
        <td>
          <h5>{{ $t('user.corporate_orders_table.fields.order_status') }}:</h5>
          <span :class="`account-corporate-orders-status-${item.status}`">{{ $t(`code.corporate_orders.status.${item.status}`) }}</span>
        </td>
        <td>
          <h5>{{ $t('user.corporate_orders_table.fields.activation_code_batch_id') }}:</h5>
          <link-secondary-route
            v-if="item.activation_code_batch_id"
            :can-access="canViewOffer()"
            :route-name="'activation_code_batch_details'"
            :route-params="{ activationCodeBatchId: item.activation_code_batch_id }"
          >
            {{ item.activation_code_batch_id }}
          </link-secondary-route>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';

export default {
  name: 'user-corporate-orders-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
