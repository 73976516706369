import Vue from 'vue';
import _ from 'lodash';

const defaultPage = 1;
const paginationLimit = 20;

const instantCardVouchersPaginationLimit = 10;

const sortingWeight = {
  CA: 0,
  US: 1,
};

export default {
  namespaced: true,
  state: {
    card: {},
    cards: [],
    countryList: [],
    instantCardVouchers: [],
    instantCardVouchersPageNumber: defaultPage,
    instantCardVouchersPageCount: 0,
    instantCardVouchersItemCount: 0,

    list: {
      keyword: '',
      partner_id: '',
      project_id: '',
      status: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    card: (state) => state.card,
    cards: (state) => state.cards,
    instantCardVouchers: (state) => state.instantCardVouchers,
    instantCardVouchersPageNumber: (state) => state.instantCardVouchersPageNumber,
    instantCardVouchersPageCount: (state) => state.instantCardVouchersPageCount,
    instantCardVouchersItemCount: (state) => state.instantCardVouchersItemCount,
    listKeyword: (state) => state.list.keyword,
    listPartnerId: (state) => state.list.partner_id,
    listProjectId: (state) => state.list.project_id,
    listStatus: (state) => state.list.status,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    countryList: (state, getters, rootState, rootGetters) =>
      _.reduce(
        _.sortBy(state.countryList, [
          (i) => {
            return i.postal_code_required ? 0 : 1;
          },
          (val) => {
            return _.get(sortingWeight, val.iso_code, 2);
          },
          (val) => {
            return _.deburr(val.name[rootGetters['ui/currentLocale']]);
          },
        ]),
        (acc, item, index) => {
          if (acc.length !== 0 && acc[acc.length - 1].postal_code_required === true && item.postal_code_required === false) {
            acc.push({
              divider: true,
            });
          }
          acc.push({
            text: item.name[rootGetters['ui/currentLocale']],
            value: item.iso_code,
            postal_code_required: item.postal_code_required,
          });

          return acc;
        },
        [],
      ),
  },
  mutations: {
    instantCardVouchers(state, vouchers) {
      state.instantCardVouchers = vouchers;
    },
    instantCardVouchersPage(state, page) {
      state.instantCardVouchersPage = page;
    },
    instantCardVouchersPageCount(state, page_count) {
      state.instantCardVouchersPageCount = page_count;
    },
    instantCardVouchersItemCount(state, item_count) {
      state.instantCardVouchersItemCount = item_count;
    },
    card(state, card) {
      state.card = card;
    },
    cards(state, cards) {
      state.cards = cards;
    },
    countryList(state, list) {
      state.countryList = list;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPartnerId(state, partner_id) {
      state.list.partner_id = partner_id;
    },
    listProjectId(state, project_id) {
      state.list.project_id = project_id;
    },
    listStatus(state, status) {
      state.list.status = status;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPage(state, page) {
      state.list.pageNumber = page;
    },
  },
  actions: {
    async createCard({ commit }, params) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      return instantCardCardService.createCard(params);
    },
    async preActivate({ commit, dispatch }, { public_token }) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const result = await instantCardCardService.preActivate(public_token);
      await dispatch('getCard', public_token);
      return result;
    },
    async markAsNonPreActivated({ commit, dispatch }, { public_token }) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const result = await instantCardCardService.markAsNonPreActivated(public_token);
      await dispatch('getCard', public_token);
      return result;
    },
    async getCard({ commit, dispatch }, id) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const card = await instantCardCardService.getCard(id);

      commit('card', card);
      return card;
    },
    async listCards({ state, commit }, { keyword, partner_id, project_id, status, page, limit } = {}) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const thePartnerId = partner_id || '';
      const theProjectId = project_id || '';
      const theStatus = status || '';

      const listResults = await instantCardCardService.listCards(thePage, theLimit, theKeyword, thePartnerId, theProjectId, theStatus);

      commit('listPartnerId', thePartnerId);
      commit('listProjectId', theProjectId);
      commit('listStatus', theStatus);
      commit('listKeyword', theKeyword);
      commit('listPage', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('cards', listResults.items);
    },
    async listVouchersByInstantCardPublicToken({ commit }, { public_token, page }) {
      const voucherService = Vue.prototype.$services.voucher;
      const thePage = page || defaultPage;
      const result = await voucherService.listVouchersByInstantCardPublicToken(public_token, 1, instantCardVouchersPaginationLimit);

      commit('instantCardVouchers', result.items);
      commit('instantCardVouchersPage', thePage);
      commit('instantCardVouchersPageCount', result.page_count);
      commit('instantCardVouchersItemCount', result.item_count);
    },

    async changeInstantCardVouchersPage({ commit, state }, { public_token, page }) {
      commit('instantCardVouchersPage', page);

      const voucherService = Vue.prototype.$services.voucher;

      const accountVouchersResult = await voucherService.listVouchersByInstantCardPublicToken(public_token, page, instantCardVouchersPaginationLimit);

      commit('instantCardVouchersPageCount', accountVouchersResult.page_count);
      commit('instantCardVouchers', accountVouchersResult.items);
    },

    async initCountryList({ commit }) {
      const countriesList = await Vue.prototype.$services.instantCardCard.getInstantCardActivationCountryList();
      commit('countryList', countriesList);
    },
    async activateInstantCard({ dispatch }, params) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;

      const payload = {
        account_id: params.account_id,
        first_name: params.first_name,
        country: params.country,
        postal_code: params.postal_code,
        date_of_birth: params.date_of_birth,
      };

      const result = await instantCardCardService.activateInstantCard(params.public_token, payload);
      await dispatch('getCard', params.public_token);
      return result;
    },
    async pauseInstantCard({ dispatch }, { public_token }) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const result = await instantCardCardService.pauseInstantCard(public_token);
      await dispatch('getCard', public_token);
      return result;
    },
    async resumeInstantCard({ dispatch }, { public_token }) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const result = await instantCardCardService.resumeInstantCard(public_token);
      await dispatch('getCard', public_token);
      return result;
    },
    async destroyInstantCard({ dispatch }, { public_token }) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const result = await instantCardCardService.destroyInstantCard(public_token, { reason: `Destroy Instant Card "${public_token}"` });
      await dispatch('getCard', public_token);
      return result;
    },
    async linkCardToProject({ dispatch }, { public_token, instant_card_project_id }) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const result = await instantCardCardService.linkCardToProject({ public_token, instant_card_project_id });
      await dispatch('getCard', public_token);
      return result;
    },
    async unlinkCardFromProject({ dispatch }, { public_token }) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const result = await instantCardCardService.unlinkCardFromProject({ public_token });
      await dispatch('getCard', public_token);
      return result;
    },
    async clearListCards({ commit }) {
      commit('cards', []);
    },
  },
};
