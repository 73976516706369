<template>
  <span>
    <span v-if="status" :class="`status-${status}`">{{ $t(`user.transaction_logs_table.status.${status}`) }}</span>
    <span v-else class="info-not-available">{{ $t('system.na') }}</span>
  </span>
</template>

<script>
export default {
  name: 'user-transaction-log-status-view',
  props: {
    status: {
      type: String,
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';

.status-approved {
  font-weight: bold;
  color: $success-default;
}
.status-blocked {
  font-weight: bold;
  color: $error-default;
}
.status-Rejected {
  font-weight: bold;
  color: $error-default;
}
.status-Voided {
  font-weight: bold;
  color: $error-default;
}
</style>
