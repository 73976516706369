<template>
  <view-main title="page_title.offer_promotion_details">
    <three-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.offers')"
      :top-to="{ name: 'offers' }"
      :second-label="offerId"
      :second-to="{ name: 'offers_details', params: { offerId } }"
      :last-label="offerPromotionId"
    />

    <card slot="content" :title="$t('offer_promotions.title')" icon="fal fa-badge-percent" id="offers-promotion-information">
      <template slot="headerZoneRight">
        <label-text :label="`${$t('offer_promotions.fields.id')}`" :value="offerPromotion.id">
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              {{ offerPromotion.id }}
              <button class="clipboard" v-clipboard:copy="offerPromotion.id ? offerPromotion.id : ''">
                <v-icon small>file_copy</v-icon>
              </button>
            </v-layout>
          </p>
        </label-text>
        <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

        <label-text :label="$t(`offer_promotions.fields.offer`)" :value="offerPromotion.offer_id">
          <p v-if="offerPromotion.offer_id" class="label-text--value" slot="labelTextLink">
            <router-link :to="{ name: 'offers_details', params: { offerId: offerPromotion.offer_id } }">
              {{ offerPromotion.offer_name }} ({{ offerPromotion.offer_id }})
            </router-link>
          </p>
        </label-text>
        <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

        <label-text
          v-if="offerPromotion.type"
          :label="`${$t('offer_promotions.fields.type')}`"
          :value="$t(`offer_promotions.type.${offerPromotion.type}`)"
        />
        <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

        <label-text :label="`${$t('offer_promotions.fields.status')}`">
          <offer-promotion-status :status="offerPromotion.status" slot="labelTextLink"></offer-promotion-status>
        </label-text>
        <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

        <label-text v-if="offerPromotion.active !== undefined" :label="`${$t('offer_promotions.fields.active')}`">
          <offer-promotion-active :active="`${offerPromotion.active}`" slot="labelTextLink"></offer-promotion-active>
        </label-text>
        <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

        <label-text
          :label="`${$t('offer_promotions.fields.created')}`"
          :value="offerPromotion.creation_date | formatShortDateInverted | orNotAvailable"
        />
        <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

        <link-secondary-action v-if="showActionBtnOfferPromotion()" slot="labelTextLink">
          <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
        </link-secondary-action>
      </template>
      <template slot="content">
        <modal-action :show-modal="showUpdateOfferPromotionModal" avoid-rendering>
          <update-offer-promotion-action
            :offer-promotion="offerPromotion"
            @close="closeUpdateOfferPromotionModal"
            @updated="updateOfferPromotionSuccess"
          ></update-offer-promotion-action>
        </modal-action>

        <modal-confirm
          :show-modal="showActivateOfferPromotion"
          :title="$t(`offer_promotions.activate_action.confirm.title`)"
          context="ActivateOfferPromotionModal"
          :text="
            $t(`offer_promotions.activate_action.confirm.text`, {
              offersPromotionName: `${offerPromotionId}`,
            })
          "
          :cancel_text="`offer_promotions.activate_action.confirm.button_cancel`"
          :submit_text="`offer_promotions.activate_action.confirm.button_submit`"
          :submit_working="working"
          @submit="activateOfferPromotion"
          @close="closeActivateOfferPromotionModal"
        ></modal-confirm>
        <modal-confirm
          :show-modal="showDeactivateOfferPromotion"
          :title="$t(`offer_promotions.deactivate_action.confirm.title`)"
          context="DeactivateOfferPromotionModal"
          :text="$t(`offer_promotions.deactivate_action.confirm.text`, { offersPromotionName: `${offerPromotionId}` })"
          :cancel_text="`offer_promotions.deactivate_action.confirm.button_cancel`"
          :submit_text="`offer_promotions.deactivate_action.confirm.button_submit`"
          :submit_working="working"
          @submit="deactivateOfferPromotion"
          @close="closeDeactivateOfferPromotionModal"
        ></modal-confirm>

        <modal-confirm
          :show-modal="showDeleteOfferPromotionModal"
          :title="$t(`offer_promotions.delete_action.confirm.title`)"
          context="DeleteOfferPromotionModal"
          :text="$t(`offer_promotions.delete_action.confirm.text`, { offersPromotionName: `${offerPromotionId}` })"
          :cancel_text="`offer_promotions.delete_action.confirm.button_cancel`"
          :submit_text="`offer_promotions.delete_action.confirm.button_submit`"
          :submit_working="working"
          @submit="deleteOfferPromotion"
          @close="closeDeleteOfferPromotionModal"
        >
        </modal-confirm>

        <v-layout row>
          <h4 style="padding-top: 0px;">{{ $t('offer_promotions.fields.incentive_title') }}</h4>
        </v-layout>
        <v-layout row>
          <v-flex xs3>
            <label-text
              :label="$t(`offer_promotions.fields.reward_percent`)"
              :value="offerPromotion.bonus.reward_percent | floatToPercentage"
            ></label-text
          ></v-flex>

          <v-flex xs3
            ><label-text
              :label="$t(`offer_promotions.fields.duration_model`)"
              :value="$t(`offers.incentive_duration_models.${offerPromotion.bonus.duration.model}`)"
            ></label-text
          ></v-flex>

          <v-flex xs3>
            <label-text
              v-if="offerPromotion.bonus.duration.model === 'relative'"
              :label="$t(`offer_promotions.fields.duration`)"
              :value="$tc('offers.days', offerPromotion.bonus.duration.days, { count: offerPromotion.bonus.duration.days })"
            ></label-text>
            <label-text
              v-if="offerPromotion.bonus.duration.model === 'fixed'"
              :label="$t(`offer_promotions.fields.bonus_end_date`)"
              :value="
                (offerPromotion.bonus.duration.end_date + ' ' + offerPromotion.bonus.duration.end_time)
                  | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offerPromotion.offer_timezone })
              "
            ></label-text
          ></v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs3
            ><label-text :label="$t(`offer_promotions.fields.minimum`)" :value="offerPromotion.bonus.minimum_payment | dollarSignI18n"></label-text
          ></v-flex>

          <v-flex xs3
            ><label-text :label="$t(`offer_promotions.fields.step`)" :value="offerPromotion.bonus.step.step | dollarSignI18n"></label-text
          ></v-flex>
          <v-flex xs3
            ><label-text
              :label="$t(`offer_promotions.fields.default_payment`)"
              :value="offerPromotion.bonus.step.default_payment | dollarSignI18n"
            ></label-text
          ></v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs3
            ><label-text :label="$t(`offer_promotions.fields.limit`)" :value="offerPromotion.bonus.user_inventory_limit | dollarSignI18n"></label-text
          ></v-flex>
          <v-flex xs3 v-if="userPaymentLimit"
            ><label-text :label="$t(`offer_promotions.fields.user_payment_limit`)" :value="userPaymentLimit | currency | dollarSignI18n"></label-text
          ></v-flex>
        </v-layout>

        <v-layout row>
          <h4>{{ $t('offer_promotions.fields.availability_title') }}</h4>
        </v-layout>
        <v-layout row>
          <v-flex xs3 v-if="offerPromotion.bonus.availability && offerPromotion.bonus.availability.inventory_id"
            ><label-text :label="$t(`offer_promotions.fields.inventory`)">
              <p class="label-text--value" slot="labelTextLink">
                <router-link :to="{ name: 'inventory_details', params: { inventoryId: offerPromotion.bonus.availability.inventory_id } }">
                  {{ offerPromotion.bonus.availability.inventory.name }} ({{ offerPromotion.bonus.availability.inventory_id }})
                </router-link>
                - {{ offerPromotion.bonus.availability.inventory.available_amount | currency | dollarSignI18n }}
              </p>
            </label-text>
          </v-flex>
          <v-flex xs3 v-if="offerPromotion.bonus.availability && offerPromotion.bonus.availability.inventory_id"
            ><label-text
              :label="$t(`offer_promotions.fields.is_refillable`)"
              :value="$t(`offer_promotions.inventory_refillable.${offerPromotion.bonus.availability.is_refillable}`)"
            ></label-text
          ></v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs3>
            <label-text :label="$t(`offer_promotions.fields.display_start_date`)">
              <p class="label-text--value" slot="labelTextLink">
                {{
                  (offerPromotion.display_start_date + ' ' + offerPromotion.display_start_time)
                    | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offerPromotion.offer_timezone })
                }}
              </p>
            </label-text>
          </v-flex>

          <v-flex xs3>
            <label-text :label="$t(`offer_promotions.fields.start_date`)">
              <p class="label-text--value" slot="labelTextLink">
                {{
                  (offerPromotion.start_date + ' ' + offerPromotion.start_time)
                    | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offerPromotion.offer_timezone })
                }}
              </p>
            </label-text>
          </v-flex>
          <v-flex xs3>
            <label-text :label="$t(`offer_promotions.fields.end_date`)">
              <p class="label-text--value" slot="labelTextLink">
                {{
                  (offerPromotion.end_date + ' ' + offerPromotion.end_time)
                    | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offerPromotion.offer_timezone })
                }}
              </p>
            </label-text>
          </v-flex>
          <v-flex xs3>
            <label-text :label="$t(`offer_promotions.fields.display_end_date`)">
              <p class="label-text--value" slot="labelTextLink">
                {{
                  (offerPromotion.display_end_date + ' ' + offerPromotion.display_end_time)
                    | formatShortDateTimeNoSecondsInverted({ withAgo: true, timezone: offerPromotion.offer_timezone })
                }}
              </p>
            </label-text>
          </v-flex>
        </v-layout>
        <v-layout row>
          <h4>{{ $t('offer_promotions.fields.accounting_title') }}</h4>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs3>
            <label-text :label="$t(`offer_promotions.fields.accounting_reference_id`)" :value="offerPromotion.accounting_reference_id"> </label-text>
          </v-flex>

          <v-flex xs3>
            <label-text
              :label="$t(`offer_promotions.fields.breakage_bonus_owner`)"
              :value="offerPromotion.bonus.funding.bonus_breakage_owner_business_name"
            >
              <p v-if="offerPromotion.bonus.funding.bonus_breakage_owner_business_id" class="label-text--value" slot="labelTextLink">
                <router-link
                  :to="{ name: 'business_details', params: { businessId: offerPromotion.bonus.funding.bonus_breakage_owner_business_id } }"
                >
                  {{ offerPromotion.bonus.funding.bonus_breakage_owner_business_name }} ({{
                    offerPromotion.bonus.funding.bonus_breakage_owner_business_id
                  }})
                </router-link>
              </p>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </label-text>
          </v-flex>
        </v-layout>
        <v-layout row>
          <h4>{{ $t('offer_promotions.fields.funding_title') }}</h4>
        </v-layout>
        <v-layout row>
          <v-flex xs3>
            <label-text :label="$t(`offer_promotions.fields.contributor`)" :value="offerPromotion.bonus.funding.contributor_business_name">
              <p v-if="offerPromotion.bonus.funding.contributor_business_id" class="label-text--value" slot="labelTextLink">
                <router-link :to="{ name: 'business_details', params: { businessId: offerPromotion.bonus.funding.contributor_business_id } }">
                  {{ offerPromotion.bonus.funding.contributor_business_name }} ({{ offerPromotion.bonus.funding.contributor_business_id }})
                </router-link>
              </p>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </label-text>
          </v-flex>

          <v-flex xs3 v-if="offerPromotion.bonus.funding.trigger">
            <label-text
              :label="$t(`offer_promotions.fields.funding_trigger`)"
              :value="$t(`offers.funding_triggers.${offerPromotion.bonus.funding.trigger}`)"
            >
            </label-text>
          </v-flex>
        </v-layout>
      </template>
    </card>
    <offer-promotion-vouchers slot="content"></offer-promotion-vouchers>
  </view-main>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, renderErrorTrackable, disableWithError, security } from '@/mixins';
import { adjustValueForMinStep } from '@/utils/offer-utils';
import UpdateOfferPromotionAction from './actions/UpdateOfferPromotionAction.vue';
import OfferPromotionStatus from './components/OfferPromotionStatus.vue';
import OfferPromotionActive from './components/OfferPromotionActive.vue';
import OfferPromotionVouchers from './components/OfferPromotionVouchers.vue';

export default {
  name: 'offer-promotions-view',
  mixins: [renderErrorTrackable, actionErrorTrackable, disableWithError, security],
  components: { OfferPromotionVouchers, UpdateOfferPromotionAction, OfferPromotionStatus, OfferPromotionActive },
  props: {
    offerId: {
      type: String,
      required: true,
    },
    offerPromotionId: {
      type: String,
      required: true,
    },
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      showDeleteOfferPromotionModal: false,
      showUpdateOfferPromotionModal: false,
      showActivateOfferPromotion: false,
      showDeactivateOfferPromotion: false,
      actions: [
        {
          slotName: 'EditOfferPromotionModal',
          name: $t('offer_promotions.update'),
          action: () => this.openUpdateOfferPromotionModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showUpdateOfferPromotionFeature(),
        },
        {
          slotName: 'ActivateOfferPromotionModal',
          name: $t('offer_promotions.activate'),
          action: () => this.openActivateOfferPromotionModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.offerPromotion.active,
        },
        {
          slotName: 'DeactivateOfferPromotionModal',
          name: $t('offer_promotions.deactivate'),
          action: () => this.openDeactivateOfferPromotionModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.offerPromotion.active,
        },
        {
          slotName: 'DeleteOfferPromotionModal',
          name: $t('offer_promotions.delete'),
          action: () => this.openDeleteOfferPromotionModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showDeleteOfferPromotionFeature(),
        },
      ],
    };
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async offerId() {
      return this.fetch();
    },
    async offerPromotionId() {
      return this.fetch();
    },
  },
  computed: {
    ...mapGetters('voucher', ['vouchers']),
    ...mapGetters('offers', ['offerPromotion']),
    userPaymentLimit() {
      return this.offerPromotion.bonus.reward_percent && this.offerPromotion.bonus.user_inventory_limit
        ? adjustValueForMinStep(
            this.offerPromotion.bonus.minimum_payment,
            this.offerPromotion.bonus.step.step,
            this.offerPromotion.bonus.user_inventory_limit / this.offerPromotion.bonus.reward_percent,
          )
        : 0;
    },
  },
  methods: {
    async fetch() {
      const offerPromotion = await this.executeAction({ action: 'offers/getOfferPromotion' }, { offer_id: this.offerId, id: this.offerPromotionId });
      if (!offerPromotion) {
        this.$router.replace({ name: 'notFound' });
      }
    },
    showActionBtnOfferPromotion() {
      return this.canManageOffer();
    },
    openUpdateOfferPromotionModal() {
      this.showUpdateOfferPromotionModal = true;
    },
    closeUpdateOfferPromotionModal() {
      this.showUpdateOfferPromotionModal = false;
    },
    async updateOfferPromotionSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offer_promotions.update_action.success' });
      this.closeUpdateOfferPromotionModal();
    },
    showUpdateOfferPromotionFeature() {
      return this.canManageOffer();
    },
    async deleteOfferPromotion() {
      await this.executeAction(
        { action: 'offers/deleteOfferPromotion', name: 'DeleteOfferPromotionModal', success: this.deleteOfferPromotionSuccess },
        { id: this.offerPromotionId },
      );
    },
    async deleteOfferPromotionSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offer_promotions.delete_action.success` });
      this.closeDeleteOfferPromotionModal();
      this.$router.push({ name: 'offers_details', params: { offerId: this.offerId } });
    },
    openDeleteOfferPromotionModal() {
      this.showDeleteOfferPromotionModal = true;
    },
    closeDeleteOfferPromotionModal() {
      this.showDeleteOfferPromotionModal = false;
    },
    showDeleteOfferPromotionFeature() {
      return this.canManageOffer() && _.isEmpty(this.vouchers);
    },

    async activateOfferPromotion() {
      await this.executeAction(
        {
          action: 'offers/activateOfferPromotion',
          name: 'ActivateOfferPromotionModal',
          success: this.activateOfferPromotionSuccess,
        },
        { id: this.offerPromotionId },
      );
    },
    async activateOfferPromotionSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offer_promotions.activate_action.success` });
      await this.fetch();
      this.closeActivateOfferPromotionModal();
    },
    openActivateOfferPromotionModal(id) {
      this.showActivateOfferPromotion = true;
    },
    closeActivateOfferPromotionModal() {
      this.showActivateOfferPromotion = false;
    },
    async deactivateOfferPromotion() {
      await this.executeAction(
        {
          action: 'offers/deactivateOfferPromotion',
          name: 'DeactivateOfferPromotionModal',
          success: this.deactivateOfferPromotionSuccess,
        },
        { id: this.offerPromotionId },
      );
    },
    async deactivateOfferPromotionSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offer_promotions.deactivate_action.success` });
      await this.fetch();
      this.closeDeactivateOfferPromotionModal();
    },
    openDeactivateOfferPromotionModal(id) {
      this.showDeactivateOfferPromotion = true;
    },
    closeDeactivateOfferPromotionModal() {
      this.showDeactivateOfferPromotion = false;
    },
  },
};
</script>
