<template>
  <card :title="$t('user.transaction_logs')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.transaction_logs_section.count')"
        :value="listTransactionLogsItemCount.toString()"
      ></label-text>
    </template>
    <template slot="content">
      <user-transaction-logs-table v-if="accountTransactionLogs.length" :items="accountTransactionLogs"></user-transaction-logs-table>
      <label-none v-else>{{ $t('user.details.transaction_logs_section.no_found') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="listTransactionLogsPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="listTransactionLogsPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserTransactionLogsTable from './UserTransactionLogsTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-transaction-logs',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserTransactionLogsTable },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('account', [
      'account',
      'accountTransactionLogs',
      'listTransactionLogsPageCount',
      'listTransactionLogsPageNumber',
      'listTransactionLogsItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account() {
      await this.listTransactionLogs();
    },
    listTransactionLogsPageNumber() {
      this.pagination = this.listTransactionLogsPageNumber;
    },
    async pagination(newValue) {
      await this.listTransactionLogs(newValue);
    },
  },
  methods: {
    async listTransactionLogs(page) {
      await this.executeAction(
        {
          action: 'account/listTransactionLogs',
        },
        {
          account_id: this.account.id,
          page: page || this.listTransactionLogsPageNumber,
        },
      );
    },
  },
};
</script>
