<template>
  <view-main title="page_title.inventory_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.inventory')" :to="{ name: 'inventories' }" :last-label="inventory.name" />
    <inventory-information slot="content" />
    <inventory-business slot="content" />
    <inventory-offers slot="content" />
    <inventory-offer-promotions slot="content" />
    <inventory-mouvements slot="content" />
    <inventory-notes slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import InventoryInformation from './detail/InventoryInformation.vue';
import InventoryMouvements from './detail/InventoryMouvements.vue';
import InventoryBusiness from './detail/InventoryBusiness.vue';
import InventoryOffers from './detail/InventoryOffers.vue';
import InventoryOfferPromotions from './detail/InventoryOfferPromotions.vue';
import InventoryNotes from './detail/InventoryNotes.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'inventory-details-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { InventoryInformation, InventoryMouvements, InventoryBusiness, InventoryOffers, InventoryOfferPromotions, InventoryNotes },
  props: {
    inventoryId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('inventory', ['inventory']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async inventoryId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const inventory = await this.executeAction({ action: 'inventory/getInventory' }, this.inventoryId);
      if (!inventory) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
