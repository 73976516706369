<template>
  <table class="responsive-table" id="voucher-operations-table">
    <thead>
      <tr>
        <td>{{ $t('voucher.operations_table.id') }}</td>
        <td>{{ $t('voucher.operations_table.created') }}</td>
        <td>{{ $t('voucher.operations_table.type') }}</td>
        <td align="right">{{ $t('voucher.operations_table.amount') }}</td>
        <td>{{ $t('voucher.operations_table.card_activity_id') }}</td>
        <td>{{ $t('voucher.operations_table.reason') }}</td>
        <td>{{ $t('voucher.operations_table.agent') }}</td>
        <td>{{ $t('voucher.operations_table.transfer_from') }}</td>
        <td>{{ $t('voucher.operations_table.transfer_to') }}</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index" :style="{ color: isLowLevelOperation(item) ? 'darkgray' : 'inherit' }">
        <td>
          <h5>{{ $t('voucher.operations_table.id') }}:</h5>
          <span>{{ item.id }}</span>
        </td>
        <td>
          <h5>{{ $t('voucher.operations_table.type') }}:</h5>
          <span>{{ item.operation_date | formatShortDateTimeInverted }}</span>
        </td>
        <td>
          <h5>{{ $t('voucher.operations_table.type') }}:</h5>
          <span>{{ $t(`voucher.operations.types.${item.type}`) }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('voucher.operations_table.amount') }}:</h5>
          <span v-if="isLowLevelOperation(item)" style="color: darkgray">{{ item.amount | currency | dollarSignI18n }}</span>
          <span v-else-if="item.amount >= 0">{{ item.amount | currency | dollarSignI18n }}</span>
          <span v-else style="color: darkred">{{ item.amount | currency | dollarSignI18n }}</span>
        </td>
        <td>
          <h5>{{ $t('voucher.operations_table.card_activity_id') }}:</h5>
          <router-link
            target="_blank"
            v-if="extractCardActivityIdFromItem(item)"
            :to="{ name: 'card_activities_dashboard', query: { activity_id: item.card_activity_id, timerange: '~' } }"
          >
            {{ extractCardActivityIdFromItem(item) }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('voucher.operations_table.reason') }}:</h5>
          <span v-if="item.reason">{{ item.reason }}</span>
          <span v-else-if="item.card_transaction">{{ item.card_transaction.transaction_merchant_name }}</span>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('voucher.operations_table.agent') }}:</h5>
          <span><app-name :app_key="item.creation_by_app_key"></app-name></span>
        </td>
        <td>
          <h5>{{ $t('voucher.operations_table.transfer_from') }}:</h5>
          <link-secondary-route
            v-if="_get(item, 'transfer.voucher_from', null)"
            :route-name="'voucher_details'"
            :route-params="{ voucherId: item.transfer.voucher_from }"
          >
            {{ item.transfer.voucher_from }}
          </link-secondary-route>
        </td>
        <td>
          <h5>{{ $t('voucher.operations_table.transfer_to') }}:</h5>
          <link-secondary-route
            v-if="_get(item, 'transfer.voucher_to', null) && _get(item, 'transfer.voucher_to', null) !== voucher.id"
            :route-name="'voucher_details'"
            :route-params="{ voucherId: item.transfer.voucher_to }"
          >
            {{ item.transfer.voucher_to }}
          </link-secondary-route>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import AppName from '@/components/system/AppName';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'voucher-operations-table',
  components: { AppName },
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    items: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters('voucher', ['voucher']),
  },
  methods: {
    isLowLevelOperation(voucherOperation) {
      return (
        ['voucher_unload', 'voucher_load', 'transaction_payment', 'voucher_refund_order'].includes(voucherOperation.type) ||
        (voucherOperation.affect_available === false && voucherOperation.affect_actual === true)
      );
    },
    _get(object, path, defaultValue) {
      return _.get(object, path, defaultValue);
    },
    extractCardActivityIdFromItem(item) {
      return _.get(item, 'card_transaction.card_activity_id') || item.card_activity_id;
    },
  },
};
</script>
