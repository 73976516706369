<template>
  <card :title="$t('voucher.details.voucher_information_section.title')" icon="fal fa-money-check-alt" id="voucher-informations">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('voucher.details.voucher_information_section.id')}`" :value="voucher.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ voucher.id }}
            <button class="clipboard" v-clipboard:copy="voucher.id ? voucher.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text :label="`${$t('voucher.details.voucher_information_section.type')}`" :value="voucherTypeWithMeta" />
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text :label="`${$t('voucher.details.voucher_information_section.user')}`" :value="voucher.account_id">
        <account-email slot="labelTextLink" :id="voucher.account_id" :show-label="false" :generate-link-to-card="true"></account-email>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text :label="`${$t('voucher.details.voucher_information_section.cardholder')}`" :value="voucher.cardholder_id">
        <p slot="labelTextLink">
          <cardholder-current-card v-if="voucher.cardholder_id" :cardholder-id="voucher.cardholder_id" />
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text
        :label="`${$t('voucher.details.voucher_information_section.duration_trigger')}`"
        :value="$t('voucher.duration_trigger.' + voucher.duration_trigger)"
      />
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text :label="`${$t('voucher.details.voucher_information_section.system_status.status')}`" :value="voucher.system_status.status">
        <voucher-status-view slot="labelTextLink" :status="voucher.system_status.status" />
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text
        :label="`${$t('voucher.details.voucher_information_section.created')}`"
        :value="voucher.creation_date | formatShortDateInverted | orNotAvailable"
      />
      <div v-if="showActionBtnVoucher()" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>

      <link-secondary-action v-if="showActionBtnVoucher()" slot="labelTextLink">
        <button-secondary-actions :actions="actions" :text="$t(`system.actions`)" class="application-info--actions"></button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <modal-action :show-modal="showModal">
        <redeem-activation-code-action
          v-if="showModalByName('redeemActivationCode')"
          :offer-id="voucher.offer_id"
          :activation-code-id="voucher.activation_code_id"
          :activation-start-date="voucher.activation.start_date"
          @close="toggleModalByName('redeemActivationCode')"
          @created="onRedeemActivationCodeCreated()"
        />
        <transfer-voucher-action-to-user
          v-if="showModalByName('transferVoucherToUser')"
          :voucher="voucher"
          @close="toggleModalByName('transferVoucherToUser')"
          @updated="
            toggleModalByNameWithAction(
              'transferVoucherToUser',
              { action: 'ui/showSuccessSnackBar' },
              { text: 'voucher.details.voucher_information_section.transfer_action.success' },
            )
          "
        ></transfer-voucher-action-to-user>

        <transfer-voucher-action-to-card
          v-if="showModalByName('transferVoucherToCard')"
          :voucher="voucher"
          @close="toggleModalByName('transferVoucherToCard')"
          @updated="
            toggleModalByNameWithAction(
              'transferVoucherToCard',
              { action: 'ui/showSuccessSnackBar' },
              { text: 'voucher.details.voucher_information_section.transfer_action.success' },
            )
          "
        ></transfer-voucher-action-to-card>

        <load-voucher-to-card
          v-if="showModalByName('loadVoucherToCard')"
          :voucher="voucher"
          @close="toggleModalByName('loadVoucherToCard')"
          @updated="
            toggleModalByNameWithAction(
              'loadVoucherToCard',
              { action: 'ui/showSuccessSnackBar' },
              { text: 'voucher.details.voucher_information_section.load_action.success' },
            )
          "
        ></load-voucher-to-card>

        <modal-confirm
          v-if="showModalByName('unloadVoucher')"
          :show-modal="showModalByName('unloadVoucher')"
          :title="$t('voucher.unload_action.title')"
          context="unloadVoucher"
          :text="$t('voucher.unload_action.message', { voucher_id: voucher.id })"
          :cancel_text="'voucher.unload_action.button_cancel'"
          :submit_text="'voucher.unload_action.button_save'"
          :submit_working="working"
          @submit="submitUnloadVoucher"
          @close="toggleModalByName('unloadVoucher')"
        ></modal-confirm>

        <refund-voucher-action
          v-if="showModalByName('refundVoucher')"
          :voucher="voucher"
          @close="toggleModalByName('refundVoucher')"
          @updated="
            toggleModalByNameWithAction(
              'refundVoucher',
              { action: 'ui/showSuccessSnackBar' },
              { text: 'voucher.details.voucher_information_section.refund_action.success' },
            )
          "
        />

        <download-consumer-gift-card-order-invoice
          v-if="showModalByName('downloadConsumerGiftCardOrderInvoice')"
          :consumer-gift-card-order-id="this.voucher.consumer.consumer_gift_card_order_id"
          @close="toggleModalByName('downloadConsumerGiftCardOrderInvoice')"
          @action="toggleModalByName('downloadConsumerGiftCardOrderInvoice')"
        ></download-consumer-gift-card-order-invoice>

        <download-order-invoice
          v-if="showModalByName('downloadOrderInvoice')"
          :order-id="this.voucher.order_id"
          @close="toggleModalByName('downloadOrderInvoice')"
          @action="toggleModalByName('downloadOrderInvoice')"
        ></download-order-invoice>

        <extend-voucher-expiration-action
          v-if="showModalByName('extendVoucherExpiration')"
          :voucher="voucher"
          :number-of-days-between-expiration-and-closure="numberOfDaysBetweenExpirationAndClosure"
          @close="toggleModalByName('extendVoucherExpiration')"
          @updated="
            toggleModalByNameWithAction(
              'extendVoucherExpiration',
              { action: 'ui/showSuccessSnackBar' },
              { text: 'voucher.details.voucher_information_section.extend_action.success' },
            )
          "
        ></extend-voucher-expiration-action>

        <apply-voucher-dormancy-extension-action
          v-if="showModalByName('applyVoucherDormancyExtension')"
          :voucher="voucher"
          @close="toggleModalByName('applyVoucherDormancyExtension')"
          @updated="
            toggleModalByNameWithAction(
              'applyVoucherDormancyExtension',
              { action: 'ui/showSuccessSnackBar' },
              { text: 'voucher.details.voucher_information_section.apply_dormancy_extension.success' },
            )
          "
        ></apply-voucher-dormancy-extension-action>

        <force-close-voucher-action
          v-if="showModalByName('forceCloseVoucher')"
          :voucher="voucher"
          @close="toggleModalByName('forceCloseVoucher')"
          @updated="
            toggleModalByNameWithAction(
              'forceCloseVoucher',
              { action: 'ui/showSuccessSnackBar' },
              { text: `voucher.details.voucher_information_section.force_close_action.success` },
            )
          "
        ></force-close-voucher-action>

        <cancel-voucher-action
          v-if="showModalByName('cancelVoucher')"
          :voucher="voucher"
          @close="toggleModalByName('cancelVoucher')"
          @updated="
            toggleModalByNameWithAction(
              'cancelVoucher',
              { action: 'ui/showSuccessSnackBar' },
              { text: `voucher.details.voucher_information_section.cancel_action.success` },
            )
          "
        ></cancel-voucher-action>

        <adjust-voucher-balance-action
          v-if="showModalByName('adjustVoucherBalance')"
          :voucher="voucher"
          @close="toggleModalByName('adjustVoucherBalance')"
          @updated="
            toggleModalByNameWithAction(
              'adjustVoucherBalance',
              { action: 'ui/showSuccessSnackBar' },
              { text: `voucher.details.voucher_information_section.cancel_action.success` },
            )
          "
        ></adjust-voucher-balance-action>

        <refund-voucher-order-action
          v-if="showModalByName('refundVoucherOrder')"
          :voucher_id="voucher.id"
          @close="toggleModalByName('refundVoucherOrder')"
          @updated="
            toggleModalByNameWithAction(
              'refundVoucherOrder',
              { action: 'ui/showSuccessSnackBar' },
              { text: 'voucher.details.voucher_information_section.refund_action_order.success' },
            )
          "
        ></refund-voucher-order-action>

        <block-voucher-action
          v-if="showModalByName('blockVoucher')"
          :voucher="voucher"
          @close="toggleModalByName('blockVoucher')"
          @updated="
            toggleModalByNameWithAction(
              'blockVoucher',
              { action: 'ui/showSuccessSnackBar' },
              { text: `voucher.details.voucher_information_section.block_action.success` },
            )
          "
        ></block-voucher-action>

        <write-off-voucher-action
          v-if="showModalByName('writeOffVoucher')"
          :voucher="voucher"
          @close="toggleModalByName('writeOffVoucher')"
          @updated="
            toggleModalByNameWithAction(
              'writeOffVoucher',
              { action: 'ui/showSuccessSnackBar' },
              { text: `voucher.details.voucher_information_section.write_off_voucher_action.success` },
            )
          "
        ></write-off-voucher-action>

        <modal-confirm
          v-if="showModalByName('removeWriteOffVoucher')"
          :cancel_text="'voucher.remove_write_off_action.button_cancel'"
          :show-modal="showModalByName('removeWriteOffVoucher')"
          :submit_text="'voucher.remove_write_off_action.button_submit'"
          :submit_working="working"
          :text="$t('voucher.remove_write_off_action.message', { voucher_id: voucher.id })"
          :title="$t('voucher.remove_write_off_action.title')"
          context="removeWriteOffVoucher"
          @submit="submitRemoveWriteOffVoucher"
          @close="toggleModalByName('removeWriteOffVoucher')"
        ></modal-confirm>

        <unblock-voucher-action
          v-if="showModalByName('unblockVoucher')"
          :voucher="voucher"
          @close="toggleModalByName('unblockVoucher')"
          @updated="
            toggleModalByNameWithAction(
              'unblockVoucher',
              { action: 'ui/showSuccessSnackBar' },
              { text: `voucher.details.voucher_information_section.unblock_action.success` },
            )
          "
        ></unblock-voucher-action>
      </modal-action>
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t(`voucher.details.voucher_information_section.offer`)" :value="voucher.offer_id">
            <p slot="labelTextLink">
              <router-link v-if="voucher.offer_id" :to="{ name: 'offers_details', params: { offerId: voucher.offer_id } }">
                {{ voucherOfferBrandingName }} ({{ voucher.offer_id }})
              </router-link>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t(`voucher.details.voucher_information_section.publisher`)" :value="voucher.publisher_id">
            <p slot="labelTextLink">
              <router-link v-if="voucher.publisher_id" :to="{ name: 'closed_loop_details', params: { publishersId: voucher.publisher_id } }">
                <publishers-name :class="{ 'info-not-available': !voucher.publisher_id }" :id="voucher.publisher_id" />
              </router-link>
              <label-none v-else>{{ $t('voucher.details.voucher_information_section.publisher_na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="voucher.offer_promotion_id">
          <label-text :label="$t(`voucher.details.voucher_information_section.offer_promotion_id`)" :value="voucher.offer_promotion_id">
            <p slot="labelTextLink">
              <router-link
                :to="{ name: 'offer_promotion_detail', params: { offerId: voucher.offer_id, offerPromotionId: voucher.offer_promotion_id } }"
              >
                {{ voucher.offer_promotion_id }}
              </router-link>
            </p>
          </label-text>
        </v-flex>

        <v-flex xs3 v-if="voucher.instant_card_id">
          <label-text :label="$t(`voucher.details.voucher_information_section.instant_card_id`)" :value="voucher.instant_card_id">
            <p slot="labelTextLink">
              <router-link :to="{ name: 'instant_cards', query: { keyword: voucher.instant_card_id } }">
                {{ voucher.instant_card_id }}
              </router-link>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.purchase_channel`)"
            :value="voucher.purchase_channel ? $t('purchase_channel.' + voucher.purchase_channel) : null"
          ></label-text>
        </v-flex>
        <v-flex v-if="voucher.buyer_account_id" xs3>
          <label-text :label="`${$t('voucher.details.voucher_information_section.owner_account')}`" :value="voucher.buyer_account_id">
            <account-email :id="voucher.buyer_account_id" slot="labelTextLink" :generate-link-to-card="true" :show-label="false"></account-email>
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="voucher.order_id && order">
          <label-text :label="$t(`voucher.details.voucher_information_section.order_id`)" :value="voucher.order_id">
            <p slot="labelTextLink">
              <a target="_blank" :href="stripeSearchUrl(orderStripeTransactionId)">
                {{ voucher.order_id }}
              </a>
            </p>
          </label-text>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.activation_start_date`)"
            :value="voucher.activation.start_date | formatShortDateTimeInverted({ withAgo: true })"
          >
            <p slot="labelTextLink">
              <span class="font-weight-bold" :class="{ 'label-error': isBeforeActivationStartDate }" v-if="voucher.activation.start_date">
                {{ voucher.activation.start_date | formatShortDateTimeInverted({ withAgo: true }) }}
              </span>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.activation_date`)"
            :value="voucher.activation_date | formatShortDateInverted({ withAgo: true })"
          >
            <p slot="labelTextLink">
              <span class="font-weight-bold" v-if="voucher.activation_date">{{
                voucher.activation_date | formatShortDateInverted({ withAgo: true })
              }}</span>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t(`voucher.details.voucher_information_section.activation_code`)" :value="voucher.activation_code_id">
            <p slot="labelTextLink">
              <span v-if="voucher.activation_code_id">
                <router-link :to="{ name: 'activation_code_details', params: { activationCodeId: voucher.activation_code_id } }">
                  {{ voucher.activation_code_id }}
                </router-link>
                <button class="clipboard" v-clipboard:copy="voucher.activation_code_id ? voucher.activation_code_id : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </span>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <h4>{{ $t(`voucher.details.voucher_information_section.balance`) }}</h4>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.amount`)"
            :value="voucher.amount | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.remaining`)"
            :value="voucher.remaining_amount | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.payment`)"
            :value="voucher.payment_amount | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.payment_remaining`)"
            :value="voucher.remaining_payment_amount | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.closure_date`)"
            :value="voucher.closure_date | formatShortDateInverted({ withAgo: true })"
          >
            <p slot="labelTextLink">
              <span class="font-weight-bold" v-if="voucher.closure_date">{{
                voucher.closure_date | formatShortDateInverted({ withAgo: true })
              }}</span>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.bonus`)"
            :value="voucher.bonus_amount | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.bonus_remaining`)"
            :value="voucher.remaining_bonus_amount | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`voucher.details.voucher_information_section.bonus_expiration_date`)"
            :value="voucher.bonus_expiration_date | formatShortDateInverted({ withAgo: true })"
          >
            <p slot="labelTextLink">
              <span class="font-weight-bold" v-if="voucher.bonus_expiration_date">{{
                voucher.bonus_expiration_date | formatShortDateInverted({ withAgo: true })
              }}</span>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout v-if="voucher.transferred_to_voucher_id" row wrap>
        <v-flex xs3>
          <label-text :label="$t(`voucher.details.voucher_information_section.transferred_to_voucher_id`)">
            <p slot="labelTextLink">
              <router-link
                v-if="voucher.transferred_to_voucher_id"
                :to="{ name: 'voucher_details', params: { voucherId: voucher.transferred_to_voucher_id } }"
              >
                {{ voucher.transferred_to_voucher_id }}
              </router-link>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row v-if="isConsumerGiftCardVoucher">
        <h4>{{ $t(`voucher.details.voucher_information_section.consumer_gift_program`) }}</h4>
      </v-layout>
      <v-layout row v-if="isConsumerGiftCardVoucher">
        <v-flex xs3>
          <label-text :label="$t(`voucher.details.voucher_information_section.owner_account`)" :value="voucher.consumer.owner_account_id">
            <account-email slot="labelTextLink" :id="voucher.consumer.owner_account_id" :show-label="false" :generate-link-to-card="true" />
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t(`voucher.details.voucher_information_section.order_id`)" :value="voucher.consumer.consumer_gift_card_order_id">
            <p slot="labelTextLink">
              <a target="_blank" :href="stripeSearchUrl(consumerStripeTransactionId)">
                {{ voucher.consumer.consumer_gift_card_order_id }}
              </a>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout v-if="isVoucherDormancyEnabled" row>
        <h4>{{ $t(`voucher.details.voucher_information_section.dormancy.title`) }}</h4>
      </v-layout>
      <v-layout row v-if="isVoucherDormancyEnabled">
        <v-flex xs3>
          <label-text
            :label="$t('voucher.details.voucher_information_section.dormancy.dormancy_fee')"
            :value="voucher.dormancy.settings.fixed_fee | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t('voucher.details.voucher_information_section.dormancy.dormancy_trigger')"
            :value="voucher.dormancy.settings.last_activity_trigger_in_months"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t('voucher.details.voucher_information_section.dormancy.extension_in_months')"
            :value="voucher.dormancy.settings.extension_in_months"
          ></label-text>
        </v-flex>
      </v-layout>
      <v-layout row v-if="isVoucherDormancyEnabled">
        <v-flex xs3>
          <label-text
            :label="$t('voucher.details.voucher_information_section.dormancy.dormancy_trigger_date')"
            :value="voucher.dormancy.start_date | formatShortDateInverted({ withAgo: true, returnNullIfNoDate: true, timezone: 'Canada/Eastern' })"
          >
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t('voucher.details.voucher_information_section.dormancy.dormancy_last_fee_date')"
            :value="voucher.dormancy.last_fee_date | formatShortDateInverted({ withAgo: true, returnNullIfNoDate: true })"
          >
          </label-text>
        </v-flex>
      </v-layout>

      <v-layout row>
        <h4>{{ $t('voucher.details.voucher_information_section.accounting.title') }}</h4>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3 v-if="voucher.accounting">
          <label-text
            :label="$t(`voucher.details.voucher_information_section.accounting.customer_type.label`)"
            :value="$t(`offers.customer_type.${voucher.accounting.customer_type}`)"
          />
        </v-flex>
        <v-flex xs3 v-if="voucher.accounting">
          <label-text :label="$t(`voucher.details.voucher_information_section.accounting.reference_id`)" :value="voucher.accounting.reference_id" />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3 v-if="voucher.accounting && voucher.accounting.bonus_breakage_owner">
          <label-text
            :label="$t(`voucher.details.voucher_information_section.accounting.breakage_bonus_owner`)"
            :value="voucher.accounting.bonus_breakage_owner.name"
          >
            <p v-if="voucher.accounting.bonus_breakage_owner_business_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'business_details', params: { businessId: voucher.accounting.bonus_breakage_owner_business_id } }">
                {{ voucher.accounting.bonus_breakage_owner.name }}
                ({{ voucher.accounting.bonus_breakage_owner_business_id }})
              </router-link>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="voucher.accounting && voucher.accounting.payment_breakage_owner">
          <label-text
            :label="$t(`voucher.details.voucher_information_section.accounting.breakage_payment_owner`)"
            :value="voucher.accounting.payment_breakage_owner.name"
          >
            <p v-if="voucher.accounting.payment_breakage_owner_business_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'business_details', params: { businessId: voucher.accounting.payment_breakage_owner_business_id } }">
                {{ voucher.accounting.payment_breakage_owner.name }}
                ({{ voucher.accounting.payment_breakage_owner_business_id }})
              </router-link>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row v-if="voucher.write_off">
        <h4>{{ $t('voucher.details.voucher_information_section.write_off.title') }}</h4>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3 v-if="voucher.write_off">
          <label-text
            :label="$t(`voucher.details.voucher_information_section.write_off.date`)"
            :value="voucher.write_off.date | formatShortDateTimeInverted"
          />
        </v-flex>
        <v-flex xs3 v-if="voucher.write_off">
          <label-text :label="$t('voucher.details.voucher_information_section.write_off.by_app_key')" :value="voucher.write_off.by_app_key">
            <p slot="labelTextLink" class="label-text--value">
              <app-name v-if="voucher.write_off.by_app_key" :app_key="voucher.write_off.by_app_key" />
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
        <v-flex v-if="voucher.write_off" xs6>
          <label-text :label="$t(`voucher.details.voucher_information_section.write_off.reason`)" :value="voucher.write_off.reason" />
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import ExtendVoucherExpirationAction from '../actions/ExtendVoucherExpirationAction';
import CancelVoucherAction from '../actions/CancelVoucherAction';
import BlockVoucherAction from '../actions/BlockVoucherAction';
import UnblockVoucherAction from '../actions/UnblockVoucherAction';
import ForceCloseVoucherAction from '../actions/ForceCloseVoucherAction';
import RefundVoucherAction from '../actions/RefundVoucherAction';
import RefundVoucherOrderAction from '../actions/RefundVoucherOrderAction';
import TransferVoucherActionToUser from '../actions/TransferVoucherToUserAction';
import TransferVoucherActionToCard from '../actions/TransferVoucherToCardAction';
import AdjustVoucherBalanceAction from '../actions/AdjustVoucherBalanceAction';
import ApplyVoucherDormancyExtensionAction from '../actions/ApplyVoucherDormancyExtensionAction';
import LoadVoucherToCard from '../actions/LoadVoucherToCardAction';
import AccountEmail from './../../account/AccountEmail';
import VoucherStatusView from '../VoucherStatusView';
import VoucherStatus from '@/enums/voucher-status';
import RedeemActivationCodeAction from '@/components/activation_code/actions/RedeemActivationCodeAction.vue';

import WriteOffVoucherAction from '@/components/voucher/actions/WriteOffVoucherAction.vue';

import { mapGetters } from 'vuex';
import Vue from 'vue';
import PublishersName from '@/components/publishers/PublishersName';
import _ from 'lodash';
import moment from 'moment';
import DownloadConsumerGiftCardOrderInvoice from '@/components/voucher/actions/DownloadConsumerGiftCardOrderInvoice.vue';
import DownloadOrderInvoice from '@/components/voucher/actions/DownloadOrderInvoice.vue';
import LabelNone from '@/components/system/Labels/LabelNone.vue';
import AppName from '@/components/system/AppName.vue';

export default {
  name: 'voucher-information',
  components: {
    AppName,
    LabelNone,
    DownloadConsumerGiftCardOrderInvoice,
    DownloadOrderInvoice,
    ExtendVoucherExpirationAction,
    AccountEmail,
    VoucherStatusView,
    CancelVoucherAction,
    ForceCloseVoucherAction,
    RefundVoucherAction,
    RefundVoucherOrderAction,
    PublishersName,
    TransferVoucherActionToUser,
    TransferVoucherActionToCard,
    LoadVoucherToCard,
    AdjustVoucherBalanceAction,
    RedeemActivationCodeAction,
    ApplyVoucherDormancyExtensionAction,
    BlockVoucherAction,
    UnblockVoucherAction,
    WriteOffVoucherAction,
  },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    const $t = this.$t.bind(this);
    return {
      modal: {
        cancelVoucher: false,
        forceCloseVoucher: false,
        refundVoucher: false,
        refundVoucherOrder: false,
        transferVoucherToUser: false,
        transferVoucherToCard: false,
        loadVoucherToCard: false,
        unloadVoucher: false,
        adjustVoucherBalance: false,
        extendVoucherExpiration: false,
        redeemActivationCode: false,
        downloadConsumerGiftCardOrderInvoice: false,
        downloadOrderInvoice: false,
        applyVoucherDormancyExtension: false,
        blockVoucher: false,
        unblockVoucher: false,
        writeOffVoucher: false,
        removeWriteOffVoucher: false,
      },
      order: null,
      consumerGiftCardOrder: null,
      actions: [
        {
          slotName: 'downloadOrderInvoice',
          name: $t(`voucher.details.voucher_information_section.downloadInvoice`),
          action: () => this.toggleModalByName('downloadOrderInvoice'),
          isAvailable: () => this.voucher.order_id,
        },
        {
          slotName: 'downloadConsumerGiftCardOrderInvoice',
          name: $t(`voucher.details.voucher_information_section.downloadInvoice`),
          action: () => this.toggleModalByName('downloadConsumerGiftCardOrderInvoice'),
          isAvailable: () => _.get(this.voucher, 'consumer.consumer_gift_card_order_id'),
        },
        {
          slotName: 'redeemActivationCodeModal',
          name: $t('voucher.details.voucher_information_section.redeem_activation_code'),
          action: () => this.toggleModalByName('redeemActivationCode'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showRedeemActivationCodeFeature(),
        },
        {
          slotName: 'transferVoucherAnotherUserModal',
          name: $t('voucher.details.voucher_information_section.transfer_voucher_to_another_user'),
          action: () => this.toggleModalByName('transferVoucherToUser'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showTransferVoucherToUserFeature(),
        },
        {
          slotName: 'transferVoucherAnotherCardModal',
          name: $t('voucher.details.voucher_information_section.transfer_voucher_to_another_card'),
          action: () => this.toggleModalByName('transferVoucherToCard'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showTransferVoucherToCardFeature(),
        },
        {
          slotName: 'loadVoucherModal',
          name: $t('voucher.details.voucher_information_section.load_voucher_to_card'),
          action: () => this.toggleModalByName('loadVoucherToCard'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showLoadToCardFeature(),
        },
        {
          slotName: 'unloadVoucherModal',
          name: $t('voucher.details.voucher_information_section.unload_from_card'),
          action: () => this.toggleModalByName('unloadVoucher'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showUnloadVoucherFeature(),
        },
        {
          slotName: 'refundVoucherModal',
          name: $t(`voucher.details.voucher_information_section.refund_voucher_transaction`),
          action: () => this.toggleModalByName('refundVoucher'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showRefundVoucherFeature(),
        },
        {
          slotName: 'refundVoucherOrderModal',
          name: $t(`voucher.details.voucher_information_section.refund_voucher_paysafe`),
          action: () => this.toggleModalByName('refundVoucherOrder'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showRefundVoucherOrderFeature(),
        },
        {
          slotName: 'extendVoucherExpirationModal',
          name: $t('voucher.details.voucher_information_section.extend_voucher_expiration'),
          action: () => this.toggleModalByName('extendVoucherExpiration'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showExtendVoucherFeature(),
        },
        {
          slotName: 'applyVoucherDormancyExtension',
          name: $t('voucher.details.voucher_information_section.apply_voucher_dormancy_extension'),
          action: () => this.toggleModalByName('applyVoucherDormancyExtension'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showApplyVoucherDormancyExtensionFeature(),
        },
        {
          slotName: 'blockVoucherModal',
          name: $t('voucher.details.voucher_information_section.block_voucher'),
          action: () => this.toggleModalByName('blockVoucher'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showBlockVoucherFeature(),
        },
        {
          slotName: 'unblockVoucherModal',
          name: $t('voucher.details.voucher_information_section.unblock_voucher'),
          action: () => this.toggleModalByName('unblockVoucher'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showUnblockVoucherFeature(),
        },
        {
          slotName: 'writeOffVoucherModal',
          name: $t('voucher.details.voucher_information_section.write_off_voucher'),
          action: () => this.toggleModalByName('writeOffVoucher'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.disableWriteOffVoucherFeature(),
        },
        {
          slotName: 'removeWriteOffVoucherModal',
          name: $t('voucher.details.voucher_information_section.remove_write_off_voucher'),
          action: () => this.toggleModalByName('removeWriteOffVoucher'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.disableRemoveWriteOffVoucherFeature(),
        },
        {
          slotName: 'forceCancelVoucherModal',
          name: $t('voucher.details.voucher_information_section.force_closure_voucher'),
          action: () => this.toggleModalByName('forceCloseVoucher'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showForceClosureVoucherFeature(),
        },
        {
          slotName: 'cancelVoucherModal',
          name: $t('voucher.details.voucher_information_section.cancel_voucher'),
          action: () => this.toggleModalByName('cancelVoucher'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showCancelVoucherFeature(),
        },
        {
          slotName: 'adjustVoucherBalanceModal',
          name: $t('voucher.details.voucher_information_section.adjust_balance'),
          action: () => this.toggleModalByName('adjustVoucherBalance'),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showAdjustVoucherBalance(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('account', ['loadableCards']),
    ...mapGetters('voucher', ['voucher']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('voucher', ['voucherOperations']),
    isBeforeActivationStartDate() {
      return new Date() < new Date(this.voucher.activation.start_date);
    },
    isConsumerGiftCardVoucher() {
      return this.voucher.type === 'gift_certificate' && !!_.get(this, 'voucher.consumer.owner_account_id', false);
    },
    voucherOfferBrandingName() {
      return _.get(this.voucher, 'offer.branding_name');
    },
    isVoucherDormancyEnabled() {
      return _.get(this.voucher, 'dormancy.enabled', false);
    },
    voucherTypeWithMeta() {
      const type = this.$t(`voucher.types.${this.voucher.type || 'none'}`);

      let detail = this.$t(`voucher.voucher_types.${this.voucher.voucher_type}`);

      if (this.voucher.type === 'bonus' && _.get(this.voucher, 'offer.restricted_to_members', false)) {
        detail += ` - ${this.$t(`voucher.details.voucher_information_section.restricted_to_members`)}`;
      }

      detail = detail ? `(${detail})` : '';

      return `${type} ${detail}`;
    },
    consumerStripeTransactionId() {
      return _.get(this, 'consumerGiftCardOrder.stripe_transaction_id', null);
    },
    orderStripeTransactionId() {
      return _.get(this, 'order.stripe_transaction_id', null);
    },
    showModal() {
      return _.some(this.modal, (value) => value);
    },
    numberOfDaysBetweenExpirationAndClosure() {
      if (!_.get(this.voucher, 'closure_date')) {
        return 0;
      }

      const expirationDate = _.get(this.voucher, 'bonus_expiration_date');
      const closureDate = _.get(this.voucher, 'closure_date');
      const expirationMoment = moment(expirationDate);
      const closureMoment = moment(closureDate);
      return closureMoment.diff(expirationMoment, 'days');
    },
    hasAnotherCardAvailable() {
      return this.loadableCards.filter((card) => card.cardholder_id !== this.voucher.cardholder_id).length > 0;
    },
  },
  watch: {
    async voucher() {
      this.order = null;

      if (this.voucher.order_id) {
        this.order = await this.getOrder(this.voucher.order_id);
      }

      if (_.get(this, 'voucher.consumer.consumer_gift_card_order_id', false)) {
        this.consumerGiftCardOrder = await this.getConsumerGiftCardOrder(this.voucher.consumer.consumer_gift_card_order_id);
      }

      if (this.voucher.account_id) {
        await this.executeAction(
          {
            action: 'account/listLoadableCardsByAccountId',
            name: 'listLoadableCardsByAccountId',
          },
          this.voucher.account_id,
        );
      }
    },
  },
  methods: {
    stripeSearchUrl(id) {
      if (!id) {
        return '';
      }

      if (id.startsWith('pi_')) {
        return `${process.env.VUE_APP_STRIPE_PAYMENTS_URL}/${id}`;
      }

      return `${process.env.VUE_APP_STRIPE_INVOICES_URL}/${id}`;
    },
    showModalByName(name) {
      return this.modal[name];
    },
    toggleModalByName(name) {
      this.modal[name] = !this.modal[name];
    },
    async toggleModalByNameWithAction(name, action, params) {
      await this.executeAction(action, params);
      this.toggleModalByName(name);
    },
    async getOrder(id) {
      const accountService = Vue.prototype.$services.account;
      return await accountService.getOrder(id);
    },
    async getConsumerGiftCardOrder(id) {
      const accountService = Vue.prototype.$services.account;
      return await accountService.getConsumerGiftCardOrder(id);
    },
    voucherIsReferringAnOrder() {
      return this.voucher.order_id || _.get(this.voucher, 'consumer.consumer_gift_card_order_id');
    },
    voucherIsActive() {
      return _.get(this.voucher, 'system_status.status') === VoucherStatus.ACTIVE;
    },
    voucherIsCanceled() {
      return _.get(this.voucher, 'system_status.status') === VoucherStatus.CANCELED;
    },
    voucherIsClosed() {
      return _.get(this.voucher, 'system_status.status') === VoucherStatus.CLOSED;
    },
    voucherHasPosTransaction() {
      return _.get(this.voucher, 'state.has_pos_transaction');
    },
    voucherIsInert() {
      return this.voucher.duration_trigger === 'activation' && this.voucherIsPendingActivation;
    },
    voucherIsPendingActivation() {
      return _.get(this.voucher, 'system_status.status') === VoucherStatus.PENDING_ACTIVATION;
    },
    canWriteOffStatuses() {
      return [VoucherStatus.CANCELED, VoucherStatus.CLOSED];
    },
    disableRemoveWriteOffVoucherFeature() {
      return !_.includes(this.canWriteOffStatuses(), _.get(this.voucher, 'system_status.status')) || !this.voucher.write_off;
    },
    disableWriteOffVoucherFeature() {
      return !_.includes(this.canWriteOffStatuses(), _.get(this.voucher, 'system_status.status')) || !!this.voucher.write_off;
    },
    showRedeemActivationCodeFeature() {
      return this.voucherIsPendingActivation() && !_.get(this.voucher, 'instant_card_id');
    },
    showTransferVoucherToUserFeature() {
      return this.canManageOffer() && this.voucherIsActive() && !this.voucherHasPosTransaction() && !this.voucher.blocked;
    },
    showTransferVoucherToCardFeature() {
      return (
        this.canManageOffer() &&
        this.voucherIsActive() &&
        this.voucher.cardholder_id &&
        this.hasAnotherCardAvailable &&
        !this.voucherHasPosTransaction()
      );
    },
    showLoadToCardFeature() {
      return (
        this.canManageOffer() &&
        this.voucherIsActive() &&
        !this.voucher.cardholder_id &&
        this.loadableCards.length > 0 &&
        !this.voucher.blocked &&
        !this.voucherHasPosTransaction()
      );
    },
    showActionBtnVoucher() {
      return (
        this.canManageOffer() && (this.voucherIsActive() || this.voucherIsPendingActivation() || this.voucherIsCanceled() || this.voucherIsClosed())
      );
    },
    showRefundVoucherFeature() {
      return this.canManageOffer() && this.voucherIsActive() && !this.voucher.blocked;
    },
    showRefundVoucherOrderFeature() {
      return (
        this.canManageOffer() &&
        (this.voucherIsActive() || this.voucherIsPendingActivation()) &&
        this.voucherIsReferringAnOrder() &&
        ((this.consumerGiftCardOrder && this.consumerGiftCardOrder.payment_gateway === 'paysafe') ||
          (this.order && this.order.payment_gateway === 'paysafe'))
      );
    },
    showExtendVoucherFeature() {
      return this.canManageOffer() && (this.voucherIsActive() || this.voucherIsPendingActivation()) && this.voucher.bonus_expiration_date !== null;
    },
    showBlockVoucherFeature() {
      return this.canManageOffer() && !this.voucher.blocked && !this.voucher.cardholder_id;
    },
    showUnblockVoucherFeature() {
      return this.canManageOffer() && this.voucher.blocked;
    },
    showApplyVoucherDormancyExtensionFeature() {
      return (
        this.canManageOffer() &&
        (this.voucherIsActive() || this.voucherIsPendingActivation()) &&
        this.voucher.bonus_expiration_date !== null &&
        _.get(this.voucher, 'dormancy.enabled') &&
        _.get(this.voucher, 'dormancy.settings.extension_in_months') > 0 &&
        !_.get(this.voucher, 'dormancy.last_fee_date') &&
        !_.some(this.voucherOperations, (elem) => elem.type === 'voucher_apply_dormancy_extension')
      );
    },
    showCancelVoucherFeature() {
      return this.canManageOffer() && (this.voucherIsActive() || this.voucherIsPendingActivation());
    },
    showForceClosureVoucherFeature() {
      return (
        this.canManageOffer() &&
        (this.voucherIsActive() || (this.voucher.duration_trigger === 'purchase' && this.voucherIsPendingActivation)) &&
        !this.voucher.blocked &&
        !this.voucherIsClosed()
      );
    },
    showUnloadVoucherFeature() {
      return this.canManageOffer() && this.voucherIsActive() && this.voucher.cardholder_id && !this.voucherHasPosTransaction();
    },
    showAdjustVoucherBalance() {
      return this.isAgentInternal() && !this.voucherIsInert;
    },
    async goToCardFromCardholderId(cardholderId) {
      const card = await Vue.prototype.$services.card.getLatestCardByCardholderId(cardholderId);

      if (!card) {
        return;
      }

      this.$router.push({ name: 'card', params: { cardPublicToken: card.public_token } });
    },
    async onRedeemActivationCodeCreated() {
      await this.executeAction(
        {
          action: 'voucher/getVoucher',
          name: 'getVoucher',
          success: await this.executeAction(
            { action: 'ui/showSuccessSnackBar' },
            { text: `voucher.details.voucher_information_section.redeem_action.success` },
          ),
        },
        this.voucher.id,
      );

      return this.toggleModalByName('redeemActivationCode');
    },
    async submitRemoveWriteOffVoucher() {
      const validationComplete = await this.$validator.validateAll();
      if (!validationComplete) {
        return;
      }

      await this.executeAction(
        {
          action: 'voucher/removeWriteOffVoucher',
          name: 'removeWriteOffVoucher',
          success: await this.executeAction(
            { action: 'ui/showSuccessSnackBar' },
            { text: `voucher.details.voucher_information_section.remove_write_off_voucher_action.success` },
          ),
        },
        {
          id: this.voucher.id,
        },
      );

      this.toggleModalByName('removeWriteOffVoucher');
    },
    async submitUnloadVoucher() {
      const validationComplete = await this.$validator.validateAll();
      if (!validationComplete) {
        return;
      }

      await this.executeAction(
        {
          action: 'voucher/unloadVoucher',
          name: 'unloadVoucher',
          success: await this.executeAction(
            { action: 'ui/showSuccessSnackBar' },
            { text: `voucher.details.voucher_information_section.unload_action.success` },
          ),
        },
        this.voucher.id,
      );

      this.toggleModalByName('unloadVoucher');
    },
  },
};
</script>
