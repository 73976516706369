<template>
  <card :title="$t('user.bonus_orders')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.bonus_orders_section.count')"
        :value="accountBonusOrdersItemCount.toString()"
      ></label-text>
    </template>
    <template slot="content">
      <user-bonus-orders-table v-if="accountBonusOrders.length" :items="accountBonusOrders"></user-bonus-orders-table>
      <label-none v-else>{{ $t('user.details.bonus_orders_section.no_found') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="accountBonusOrdersPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="accountBonusOrdersPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserBonusOrdersTable from './UserBonusOrdersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-consumer-gift-card-orders',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserBonusOrdersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('account', [
      'account',
      'accountBonusOrders',
      'accountBonusOrdersPageCount',
      'accountBonusOrdersPageNumber',
      'accountBonusOrdersItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account() {
      await this.fetchBonusOrders();
    },
    accountBonusOrdersPageNumber() {
      this.pagination = this.accountBonusOrdersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'account/changeAccountBonusOrdersPage',
          success: this.success,
        },
        {
          id: this.account.id,
          page: newValue,
        },
      );
    },
  },
  methods: {
    async fetchBonusOrders() {
      await this.executeAction({ action: 'account/listBonusOrdersByAccountId' }, this.account.id, this.accountBonusOrdersPageNumber);
    },
  },
};
</script>
