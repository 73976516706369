import { render, staticRenderFns } from "./UserBonusOrders.vue?vue&type=template&id=12f52e9f"
import script from "./UserBonusOrders.vue?vue&type=script&lang=js"
export * from "./UserBonusOrders.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports