<template>
  <!-- transaction_logs_table -->
  <v-flex>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('user.transaction_logs_table.creation_date') }}</td>
          <td>{{ $t('user.transaction_logs_table.offer') }}</td>
          <td>{{ $t('user.transaction_logs_table.purchase_channel') }}</td>
          <td align="right">{{ $t('user.transaction_logs_table.amount') }}</td>
          <td>{{ $t('user.transaction_logs_table.outcome') }}</td>
          <td>{{ $t('user.transaction_logs_table.card') }}</td>
          <td>{{ $t('user.transaction_logs_table.postal_code') }}</td>
          <td>{{ $t('user.transaction_logs_table.ip_address') }}</td>
          <td>{{ $t('user.transaction_logs_table.ip_geolocation') }}</td>
          <td>{{ $t('user.transaction_logs_table.protection') }}</td>
          <td>{{ $t('user.transaction_logs_table.fingerprint') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('user.transaction_logs_table.creation_date') }}:</h5>
            <span>{{ item.creation_date | formatShortDateTimeInverted }}</span>
          </td>

          <td :title="item.offer_name">
            <h5>{{ $t('user.transaction_logs_table.offer') }}:</h5>
            <router-link v-if="item.offer_id" :to="{ name: 'offers_details', params: { offerId: item.offer_id } }">
              {{ item.offer_id }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>

          <td>
            <h5>{{ $t('user.transaction_logs_table.purchase_channel') }}:</h5>
            <span>{{ $t('purchase_channel.' + item.purchase_channel) }}</span>
          </td>

          <td align="right">
            <h5>{{ $t('user.transaction_logs_table.amount') }}:</h5>
            <span>{{ item.amount | currency | dollarSignI18n }}</span>
          </td>

          <td
            :title="
              item.outcome.payment_gateway_error_code || item.outcome.reason_code
                ? item.outcome.payment_gateway_error_message || item.outcome.reason_message
                : ''
            "
          >
            <h5>{{ $t('user.transaction_logs_table.outcome') }}:</h5>
            <user-transaction-log-status-view slot="labelTextLink" :status="item.outcome.status" />
            <span v-if="item.outcome.payment_gateway_error_code || item.outcome.reason_code" :class="`status-${item.outcome.status}`">
              ({{ item.outcome.payment_gateway_error_code || item.outcome.reason_code }})</span
            >
          </td>

          <td>
            <h5>{{ $t('user.transaction_logs_table.card') }}:</h5>
            <span v-if="item.credit_card">{{ getFormattedCard(item.credit_card) }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>

          <td>
            <h5>{{ $t('user.transaction_logs_table.postal_code') }}:</h5>
            <span v-if="item.credit_card.postal_code">{{ item.credit_card.postal_code }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>

          <td>
            <h5>{{ $t('user.transaction_logs_table.ip_address') }}:</h5>
            <span v-if="item.fingerprint.ip_address">{{ item.fingerprint.ip_address }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>

          <td>
            <h5>{{ $t('user.transaction_logs_table.ip_geolocation') }}:</h5>
            <span v-if="item.fingerprint.ip_geolocation">{{ item.fingerprint.ip_geolocation }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>

          <td>
            <h5>{{ $t('user.transaction_logs_table.protection') }}:</h5>
            <span v-if="getFormattedProtection(item.fingerprint)">{{ getFormattedProtection(item.fingerprint) }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>

          <td>
            <h5 v-if="!item.fingerprint">{{ $t('user.transaction_logs_table.fingerprint') }}:</h5>
            <span v-if="item.fingerprint">
              <a class="small-link-text" @click="showModalDialog(item.fingerprint)">{{ $t('user.transaction_logs_table.view_fingerprint') }}</a>
            </span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <modal-scrollable-zone :modal-data="modalData" :show-modal="showModal" @close="showModalFlag = false">
      <template slot="modalContent">
        <pre v-highlightjs="dataForModalContent"><code class="json"></code></pre>
      </template>
    </modal-scrollable-zone>
  </v-flex>
</template>

<script>
import UserTransactionLogStatusView from './UserTransactionLogStatusView.vue';

export default {
  name: 'user-transaction-logs-table',
  components: { UserTransactionLogStatusView },
  props: {
    items: {
      type: Array,
    },
    cardApplicant: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showModalFlag: false,
      dataForModalContent: {},
      titleModal: '',
    };
  },
  computed: {
    showModal() {
      return this.showModalFlag;
    },
    modalData() {
      return {
        content: this.dataForModalContent,
        title: this.titleModal,
      };
    },
  },
  methods: {
    getFormattedCard(card) {
      let formattedCardNumber = `${card.bin}**-****-${card.last_4_digits}`;
      formattedCardNumber = `${formattedCardNumber.slice(0, 4)}-${formattedCardNumber.slice(4)}`;
      return `${card.type} - ${formattedCardNumber}`;
    },
    getFormattedProtection(fingerprint) {
      const protections = [];
      if (fingerprint.vpn) {
        protections.push('VPN');
      }
      if (fingerprint.incognito) {
        protections.push('Incognito');
      }
      if (fingerprint.browser_tampering) {
        protections.push('Browser Tampering');
      }
      if (fingerprint.vm_detection) {
        protections.push('VM Detection');
      }
      return protections.join(', ');
    },
    hitListNames(log) {
      return log.list.map((item) => {
        return item.list_name;
      });
    },
    showModalDialog(fingerprint) {
      this.dataForModalContent = JSON.stringify(fingerprint, null, 2);
      this.showModalFlag = true;

      this.titleModal = 'Fingerprint';
      // this.titleModal = `${this.modalTitleHitType('general')} - ${this.cardApplicant.first_name} ${this.cardApplicant.last_name} (DOB: ${
      //   this.cardApplicant.date_of_birth
      // })`;
    },
    modalTitleHitType(type) {
      return this.$t(`user.aml_profile.aml_status_logs_table.details_modal_title_${type}`);
    },
  },
};
</script>
