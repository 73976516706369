<template>
  <div>
    <table class="responsive-table" id="inventory-offer_promotions-table">
      <thead>
        <tr>
          <td>{{ $t('inventory.offer_promotions_table.id') }}</td>
          <td>{{ $t('inventory.offer_promotions_table.offer_id') }}</td>
          <td>{{ $t('inventory.offer_promotions_table.program') }}</td>
          <td>{{ $t('inventory.offer_promotions_table.owner') }}</td>
          <td>{{ $t('inventory.offer_promotions_table.type') }}</td>
          <td>{{ $t('inventory.offer_promotions_table.reward_percent') }}</td>
          <td>{{ $t('inventory.offer_promotions_table.period') }}</td>
          <td>{{ $t('inventory.offer_promotions_table.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('inventory.offer_promotions_table.id') }}:</h5>
            <router-link :to="{ name: 'offer_promotion_detail', params: { offerPromotionId: item.id, offerId: item.offer_id } }">
              {{ item.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('inventory.offer_promotions_table.offer_id') }}:</h5>
            <router-link :to="{ name: 'offers_details', params: { offerId: item.offer_id } }">
              {{ `${item.offer_branding_name ? item.offer_branding_name : ''} (${item.offer_id})` }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('inventory.offer_promotions_table.program') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('inventory.offer_promotions_table.owner') }}:</h5>
            <router-link v-if="item.offer_owner_business_id" :to="{ name: 'business_details', params: { businessId: item.offer_owner_business_id } }">
              {{ `${item.offer_owner_business_name} (${item.offer_owner_business_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('inventory.offer_promotions_table.type') }}:</h5>
            <span>{{ $t('offer_promotions.type.' + item.type) }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('inventory.offer_promotions_table.reward_percent') }}:</h5>
            <span>+ {{ item.bonus.reward_percent | floatToPercentage }}</span>
          </td>
          <td>
            <h5>{{ $t('inventory.offer_promotions_table.period') }}:</h5>

            <span>{{ item.start_date | formatShortDateInverted }} - {{ item.end_date | formatShortDateInverted }}</span>
          </td>
          <td>
            <h5>{{ $t('inventory.offer_promotions_table.status') }}:</h5>
            <span><offer-promotion-status :status="item.status"></offer-promotion-status></span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="inventoryOfferPromotionsPageCount > 1" v-model="pagination" :length="inventoryOfferPromotionsPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import OfferPromotionStatus from '../../offers/offer-promotions/components/OfferPromotionStatus.vue';

export default {
  name: 'inventory-offer-promotions-table',
  mixins: [actionErrorTrackable, security],
  components: { OfferPromotionStatus },
  props: {
    items: {
      type: Array,
      required: true,
    },
    inventoryId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.inventoryOfferPromotionsPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'inventory/listInventoryOfferPromotions',
        },
        {
          id: this.inventoryId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('inventory', ['inventoryOfferPromotionsPageCount', 'inventoryOfferPromotionsPageNumber']),
  },
};
</script>
