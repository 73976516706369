<template>
  <card :title="$t('user.corporate_orders')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.corporate_orders_section.count')"
        :value="accountCorporateOrdersItemCount.toString()"
      ></label-text>
    </template>
    <template slot="content">
      <user-corporate-orders-table v-if="accountCorporateOrders.length" :items="accountCorporateOrders"></user-corporate-orders-table>
      <label-none v-else>{{ $t('user.details.corporate_orders_section.no_found') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="accountCorporateOrdersPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="accountCorporateOrdersPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserCorporateOrdersTable from './UserCorporateOrdersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-corporate-orders',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserCorporateOrdersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('account', [
      'account',
      'accountCorporateOrders',
      'accountCorporateOrdersPageCount',
      'accountCorporateOrdersPageNumber',
      'accountCorporateOrdersItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account() {
      await this.fetchCorporateOrders();
    },
    accountCorporateOrdersPageNumber() {
      this.pagination = this.accountCorporateOrdersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'account/changeAccountCorporateOrdersPage',
          success: this.success,
        },
        {
          id: this.account.id,
          page: newValue,
        },
      );
    },
  },
  methods: {
    async fetchCorporateOrders() {
      await this.executeAction({ action: 'account/listCorporateOrdersByAccountId' }, this.account.id, this.accountCorporateOrdersPageNumber);
    },
  },
};
</script>
