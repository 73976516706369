import Vue from 'vue';

const service = {
  async getInventory(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories/${id}`);
    return response.data.data;
  },
  async createInventory(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories`, payload);
    return response.data.data;
  },
  async listInventories(keyword, partner_id, business_id, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (partner_id) {
      params.partner_id = partner_id;
    }
    if (business_id) {
      params.business_id = business_id;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/inventories', page, limit, params)}`,
    );
    return response.data.data;
  },
  async searchInventories(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/inventories/search', page, limit, params)}`,
    );
    return response.data.data;
  },
  async updateInventory(id, params) {
    const payload = params;
    delete payload.available_amount;
    delete payload.current_amount;
    delete payload.business_id;

    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories/${id}`, payload);
    return response.data.data;
  },
  async adjustInventory(id, params) {
    const payload = {
      adjust_amount: params.adjust_amount,
      reason: params.reason,
    };

    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories/${id}/adjust`, payload);
    return response.data.data;
  },
  async clearInventory(id, params) {
    const payload = {
      reason: params.reason,
    };

    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories/${id}/clear`, payload);
    return response.data.data;
  },
  async createInventoryNote(inventory_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories/${inventory_id}/notes`, payload);
    return response.data.data;
  },
  async deleteInventoryNote(inventory_id, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories/${inventory_id}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listInventoryNotes(page, limit, inventory_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/inventories/${inventory_id}/notes`, page, limit)}`,
    );
    return response.data.data;
  },
  async listInventoryMouvements(id, types, page, limit) {
    const concatenatedTypes = types && types.length ? `${types.join(',')}` : undefined;
    const path = generatePath(`/publisher/v2/inventories/${id}/mouvements`, page, limit, { types: concatenatedTypes });
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${path}`);
    return response.data.data;
  },
  async listInventoryOfferPromotions(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/inventories/${id}/offer-promotions`, page, limit)}`,
    );
    return response.data.data;
  },
  async listInventoryOffers(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/inventories/${id}/offers`, page, limit)}`,
    );
    return response.data.data;
  },
  async linkBusinessToInventory(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories/${id}/business`, payload);
    return response.data.data;
  },
  async unlinkBusinessFromInventory(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/inventories/${id}/business`);
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, partner_id, business_id, types } = {}) {
  let path = startPath;
  if (page || limit || keyword || partner_id || business_id || types) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }
  if (business_id) {
    queryStrings.push(['business_id', business_id]);
  }
  if (types) {
    queryStrings.push(['types', types]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
